<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <!-- Listado cierre diario -->
        <v-data-table :loading="loading" :headers="headers" :items="saldosCaja" :items-per-page="15" dense
          class="ldpurple elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down ddpurple--text">CIERRE DIARIO</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Modal crear cierre diario -->
              <v-dialog v-model="dialog" max-width="600px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="mr-1" v-on="on" @click="saldoAnterior()" small>Cierre</v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="ddpurple" flat>
                    <v-toolbar-title>Cierre Diario</v-toolbar-title>
                    <v-spacer />
                    <span>
                      <v-icon>store</v-icon>
                      {{ datos.sucursal }}
                    </span>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12" sm="4">
                          <v-menu v-model="menuFechaCierre" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense v-model="fecha_formateada" readonly v-on="on" prepend-inner-icon="event"
                                hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_seleccionada" @input="menuFechaCierre = false"
                              @change="convertirFecha(fecha_seleccionada), desabilitar = true, saldoAnterior()">
                            </v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" sm="7">
                          <v-chip>
                            <v-avatar left>
                              <v-icon>person</v-icon>
                            </v-avatar>
                            <strong>{{ usuario.nombre }}</strong>
                          </v-chip>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container class="secondary">
                            <div class="text-center headline">
                              <strong>Totales en Soles</strong>
                            </div>
                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Inicial:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoInicialMN | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Ingresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ ingresoDiaMN | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Egresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ egresoDiaMN | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Final:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoFinalMN = saldoInicialMN + ingresoDiaMN - egresoDiaMN |
                                  formatearNumero
                                }}</v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-container>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container class="secondary">
                            <div class="text-center headline">
                              <strong>Totales en Dolares</strong>
                            </div>
                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Inicial:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoInicialME | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Ingresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ ingresoDiaME | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Egresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ egresoDiaME | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Final:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoFinalME = saldoInicialME + ingresoDiaME - egresoDiaME |
                                  formatearNumero
                                }}</v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-3 justify-center">
                    <v-btn color="error" @click="close" small>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear cierre diario -->

              <!-- Modal crear arqueo diario -->
              <v-dialog v-model="dialogArqueo" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" v-on="on" @click="saldoAnterior(), arqueoDia()" small>Arqueo
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="ddpurple" flat>
                    <v-toolbar-title>Arqueo Diario</v-toolbar-title>
                    <v-spacer />
                    <span>
                      <v-icon>store</v-icon>
                      {{ datos.sucursal }}
                    </span>
                    <v-btn icon @click="close()">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12" sm="6">
                          <v-menu v-model="menuFechaArqueo" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense v-model="fecha_formateada" readonly v-on="on"
                                prepend-inner-icon="event"></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_seleccionada" @input="menuFechaArqueo = false"
                              @change="convertirFecha(fecha_seleccionada), desabilitar = true, saldoAnterior(), arqueoDia()">
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="10" md="4" sm="4">
                          <v-select dense v-model="moneda" :items="tipo_servicios" label="Moneda"
                            :rules="[v => !!v || 'Requerido']" @change="listar"></v-select>
                        </v-col>
                        <v-col cols="2" md="2" sm="2">
                          <v-btn icon small color="print" @click="crearPDF()" :loading="desabilitar"
                            :disabled="desabilitar || !isValid">
                            <v-icon>print</v-icon>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container class="secondary">
                            <div class="text-center headline">
                              <strong>Total Soles</strong>
                            </div>
                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Inicial:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoInicialMN | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Ingresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ ingresoDiaMN | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Egresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ egresoDiaMN | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Final:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoFinalMN = saldoInicialMN + ingresoDiaMN - egresoDiaMN |
                                  formatearNumero
                                }}</v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-container>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-container class="secondary">
                            <div class="text-center headline">
                              <strong>Total Dolares</strong>
                            </div>
                            <v-list dense>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Inicial:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoInicialME | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Ingresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ ingresoDiaME | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Egresos:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ egresoDiaME | formatearNumero }}</v-list-item-action>
                              </v-list-item>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>Saldo Final:</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>{{ saldoFinalME = saldoInicialME + ingresoDiaME - egresoDiaME |
                                  formatearNumero
                                }}</v-list-item-action>
                              </v-list-item>
                            </v-list>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!-- Fin Modal arqueo diario -->
            </v-toolbar>
          </template>

          <!-- Opciones -->

          <template v-slot:item.saldo_mn="{ item }">
            <div class="text-right">
              <span>{{ item.saldo_mn | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.saldo_me="{ item }">
            <div class="text-right">
              <span>{{ item.saldo_me | formatearNumero }}</span>
            </div>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.opciones="{ item }">
            <template v-if="item.estado != 'Anulado'">
              <v-btn small icon>
                <v-icon color="error" @click="deleteItemCierre(item)">delete_forever</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones -->
        </v-data-table>
        <!-- Fin Listado cierre diario -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      saldosCaja: [],
      arqueoDelDiaS: [],
      arqueoDelDiaD: [],
      dialog: false,
      dialogArqueo: false,
      headers: [
        //listado de saldosCaja
        { text: "CdSc", value: "idsucursal", sortable: false },
        { text: "CdUs", value: "idusuario", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "SALDO MN", value: "saldo_mn", sortable: false },
        { text: "SALDO ME", value: "saldo_me", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      saldoInicialMN: "",
      saldoInicialME: "",
      ingresoDiaMN: "",
      egresoDiaMN: "",
      ingresoDiaME: "",
      egresoDiaME: "",
      saldoFinalMN: "",
      saldoFinalME: "",

      moneda: "",
      tipo_servicios: [
        { text: "Soles", value: "S" },
        { text: "Dolares", value: "D" },
      ],

      fecha: "",

      fecha_formateada: "",
      fecha_seleccionada: moment().format("YYYY-MM-DD"),

      menuFechaCierre: false,
      menuFechaArqueo: false,

      desabilitar: false,
      isValid: true,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogArqueo(val) {
      val || this.close();
    },
  },

  created() {
    this.convertirFecha(this.fecha_seleccionada);
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Saldos_Caja/Listar", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
        },
      })
        .then(function (response) {
          //console.log(response);
          me.saldosCaja = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    saldoAnterior() {
      let me = this;
      var saldosAnteriorArray = [];
      // Limpiamos variables
      me.saldoInicialMN = 0;
      me.saldoInicialME = 0;
      me.ingresoDiaMN = 0;
      me.egresoDiaMN = 0;
      me.ingresoDiaME = 0;
      me.egresoDiaME = 0;

      axios.get("api/Saldos_Caja/MostrarSaldoDiaAnterior", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          fecha: moment(me.fecha_seleccionada)
            .subtract(1, "day")
            .format("YYYY-MM-DD"),
        },
      })
        .then(function (response) {
          me.saldoDia();
          saldosAnteriorArray = response.data;
          me.saldoInicialMN = saldosAnteriorArray.saldo_mn;
          me.saldoInicialME = saldosAnteriorArray.saldo_me;
          me.desabilitar = false;
        })
        .catch(function (error) {
          me.saldoDia();
          me.desabilitar = true;
          me.snackBar({ cl: "error", msg: error.response.data });
          //console.log(error.response.data);
        });
    },

    saldoDia() {
      let me = this;
      var saldosDiaArray = [];

      axios.get("api/Movimientos/MostrarSaldoDia", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          fecha: me.fecha_seleccionada,
        },
      })
        .then(function (response) {
          saldosDiaArray = response.data;
          //console.log(saldosDiaArray);
          for (var i = 0; i < saldosDiaArray.length; i++) {
            saldosDiaArray[i].moneda == "S"
              ? (me.ingresoDiaMN = saldosDiaArray[i].ingresos)
              : (me.ingresoDiaME = saldosDiaArray[i].ingresos);
            saldosDiaArray[i].moneda == "S"
              ? (me.egresoDiaMN = saldosDiaArray[i].egresos)
              : (me.egresoDiaME = saldosDiaArray[i].egresos);
          }
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    arqueoDia() {
      let me = this;
      var arqueDiaArray = [];
      // Limpiamos variables arrays
      me.arqueoDelDiaS = [];
      me.arqueoDelDiaD = [];

      axios.get("api/Movimientos/MostrarArqueoDiario", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          fecha: me.fecha_seleccionada,
        },
      })
        .then(function (response) {
          arqueDiaArray = response.data;
          //console.log(arqueDiaArray);
          for (var i = 0; i < arqueDiaArray.length; i++) {
            if (arqueDiaArray[i].moneda == "S") {
              me.arqueoDelDiaS.push({
                movimiento: arqueDiaArray[i].movimiento,
                ingresos: arqueDiaArray[i].ingresos,
                egresos: arqueDiaArray[i].egresos,
              });
            } else if (arqueDiaArray[i].moneda == "D") {
              me.arqueoDelDiaD.push({
                movimiento: arqueDiaArray[i].movimiento,
                ingresos: arqueDiaArray[i].ingresos,
                egresos: arqueDiaArray[i].egresos,
              });
            }
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      axios.post("api/Saldos_Caja/Crear", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idusuario: parseInt(me.usuario.idusuario),
        fecha: me.fecha_seleccionada,
        saldo_mn: this.saldoFinalMN,
        saldo_me: this.saldoFinalME,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: "success", msg: response.data });
          me.close();
          me.limpiar();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    convertirFecha(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_formateada = ddmmyy;
    },

    close() {
      this.dialog = false;
      this.dialogArqueo = false;
      this.limpiar();
    },

    limpiar() {
      this.saldoInicialMN = 0;
      this.saldoInicialME = 0;
      this.ingresoDiaMN = 0;
      this.egresoDiaMN = 0;
      this.ingresoDiaME = 0;
      this.egresoDiaME = 0;
      this.arqueoDelDiaS = [];
      this.arqueoDelDiaD = [];

      this.desabilitar = false;
      this.isValid = true;

      this.fecha_seleccionada = moment().format("YYYY-MM-DD");
      this.convertirFecha(this.fecha_seleccionada); // Cargamos fecha actual a fechaseleccionada y fecha_comprobate_formateada
    },

    deleteItemCierre(item) {
      const respta = confirm(
        "¿Estás seguro de Eliminar el cierre de fecha " +
        moment(item.fecha).format("DD/MM/YYYY") +
        "?"
      );
      let me = this;

      if (respta) {
        axios.delete("api/Saldos_Caja/Eliminar/" + this.usuario.idsucursal + "/" + this.usuario.idusuario + "/" + item.idsaldo_caja + "/" + moment(item.fecha).add(1, "day").format("YYYY-MM-DD"))
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    crearPDF() {
      var columns = [
        { header: "Detalles", dataKey: "movimiento" },
        { header: "Ingresos", dataKey: "ingresos" },
        { header: "Egresos", dataKey: "egresos" },
      ];
      var body = [];
      var foot = [];

      var totalingresos = 0.0;
      var totalegresos = 0.0;

      if (this.moneda == "S") {
        for (var i = 0; i < this.arqueoDelDiaS.length; i++) {
          totalingresos =
            totalingresos + parseFloat(this.arqueoDelDiaS[i].ingresos);
          totalegresos =
            totalegresos + parseFloat(this.arqueoDelDiaS[i].egresos);
          body.push({
            movimiento: this.arqueoDelDiaS[i].movimiento,
            ingresos: numeral(this.arqueoDelDiaS[i].ingresos).format(
              "###,##0.00"
            ),
            egresos: numeral(this.arqueoDelDiaS[i].egresos).format(
              "###,##0.00"
            ),
          });

          if (i === this.arqueoDelDiaS.length - 1) {
            foot.push([
              "Total:",
              numeral(totalingresos).format("###,##0.00"),
              numeral(totalegresos).format("###,##0.00"),
            ]);
          }
        }
      } else {
        for (var i = 0; i < this.arqueoDelDiaD.length; i++) {
          totalingresos =
            totalingresos + parseFloat(this.arqueoDelDiaD[i].ingresos);
          totalegresos =
            totalegresos + parseFloat(this.arqueoDelDiaD[i].egresos);
          body.push({
            movimiento: this.arqueoDelDiaD[i].movimiento,
            ingresos: numeral(this.arqueoDelDiaD[i].ingresos).format(
              "###,##0.00"
            ),
            egresos: numeral(this.arqueoDelDiaD[i].egresos).format(
              "###,##0.00"
            ),
          });

          if (i === this.arqueoDelDiaD.length - 1) {
            foot.push([
              "Total:",
              numeral(totalingresos).format("###,##0.00"),
              numeral(totalegresos).format("###,##0.00"),
            ]);
          }
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("ARQUEO DIARIO", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
          doc.setFontSize(8);

          doc.text("ESTABLECIMIENTO: " + this.datos.sucursal, 40, 72);
          doc.text("USUARIO: " + this.usuario.nombre, 40, 86);

          doc.text("FECHA: " + this.fecha_formateada, 260, 72);
          doc.text(
            "MONEDA: " + (this.moneda == "S" ? "SOLES" : "DOLARES"),
            260,
            86
          );

          doc.text("SALDO DIA ANTERIOR:", 480, 72, { align: "right" });
          doc.text(
            this.moneda == "S"
              ? numeral(this.saldoInicialMN).format("###,##0.00")
              : numeral(this.saldoInicialME).format("###,##0.00"),
            552,
            72,
            {
              align: "right",
            }
          );

          doc.text("NUEVO SALDO:", 480, 86, { align: "right" });
          doc.text(
            this.moneda == "S"
              ? numeral(this.saldoFinalMN).format("###,##0.00")
              : numeral(this.saldoFinalME).format("###,##0.00"),
            552,
            86,
            {
              align: "right",
            }
          );
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 8,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          movimiento: { cellWidth: 315.28 },
          ingresos: { cellWidth: 100, halign: "right" },
          egresos: { cellWidth: 100, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("Arqueo" + (this.moneda == "S" ? "Soles" : "Dolares") + ".pdf");
    },

  },
};
</script>