var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"alig-start":""}},[_c('v-flex',[_c('v-data-table',{staticClass:"lcyan elevation-3",attrs:{"loading":_vm.loading,"headers":_vm.cabeceraComprasVentas,"items":_vm.movimientoPersonas,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down dcyan--text"},[_vm._v("COMPRAS/VENTAS")]),_c('v-divider',{staticClass:"mx-2 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","color":"print","disabled":_vm.desabilitar || !_vm.isValid},on:{"click":function($event){return _vm.crearPDF()}}},[_c('v-icon',[_vm._v("print")])],1),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-1",attrs:{"dense":"","search-input":_vm.buscar_persona,"items":_vm.personas,"label":"Persona","rules":[function (v) { return !!v || 'Persona es requerido'; }],"clearable":"","hide-details":""},on:{"keyup":function($event){return _vm.selectPersona()},"update:searchInput":function($event){_vm.buscar_persona=$event},"update:search-input":function($event){_vm.buscar_persona=$event}},model:{value:(_vm.idpersona),callback:function ($$v) {_vm.idpersona=$$v},expression:"idpersona"}}),_c('v-menu',{attrs:{"close-on-content-click":!_vm.menuFecha,"transition":"scale-transition","offset-y":"","min-width":"290px","close-on-click":_vm.dates.length == 2 ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-1",attrs:{"dense":"","readonly":"","hide-details":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menuFecha),callback:function ($$v) {_vm.menuFecha=$$v},expression:"menuFecha"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){_vm.dates.length == 2 ? _vm.menuFecha = false : _vm.menuFecha = true}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-btn',{attrs:{"icon":"","small":"","color":"primary","loading":_vm.desabilitar,"disabled":_vm.desabilitar || !_vm.isValid},on:{"click":_vm.listar}},[_c('v-icon',[_vm._v("search")])],1),_c('v-spacer')],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha)))])]}},{key:"item.moneda",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.moneda == 'S' ? 'S/' : '$'))])]}},{key:"item.venta",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.venta)))])])]}},{key:"item.cuentacobrada",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.cuentacobrada)))])])]}},{key:"item.efectivo",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.efectivo)))])])]}},{key:"item.compra",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.compra)))])])]}},{key:"item.cuentapagada",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.cuentapagada)))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }