var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"alig-start":""}},[_c('v-flex',[_c('v-data-table',{staticClass:"lcyan elevation-3",attrs:{"loading":_vm.loading,"headers":_vm.cabeceraCompraDetallada,"items":_vm.comprasDetalladas,"items-per-page":15,"search":_vm.buscar,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down dcyan--text"},[_vm._v("COMPRAS DETALLADAS")]),_c('v-divider',{staticClass:"mx-2 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","color":"success","disabled":_vm.desabilitar || !_vm.isValid},on:{"click":function($event){return _vm.crearXLSX()}}},[_c('v-icon',[_vm._v("description")])],1),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-1",attrs:{"dense":"","items":_vm.selectSucursales,"search-input":_vm.buscar_sucursal,"label":"Establecimiento","rules":[function (v) { return !!v || 'Requerido'; }],"hide-details":""},on:{"update:searchInput":function($event){_vm.buscar_sucursal=$event},"update:search-input":function($event){_vm.buscar_sucursal=$event},"change":function($event){_vm.comprasDetalladas = []}},model:{value:(_vm.idsucursal),callback:function ($$v) {_vm.idsucursal=$$v},expression:"idsucursal"}}),_c('v-menu',{attrs:{"close-on-content-click":!_vm.menuFecha,"transition":"scale-transition","offset-y":"","min-width":"290px","close-on-click":_vm.dates.length == 2 ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-1",attrs:{"dense":"","readonly":"","hide-details":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menuFecha),callback:function ($$v) {_vm.menuFecha=$$v},expression:"menuFecha"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){_vm.dates.length == 2 ? _vm.menuFecha = false : _vm.menuFecha = true}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-text-field',{staticClass:"mr-1",attrs:{"dense":"","label":"Búsqueda","single-line":"","hide-details":"","disabled":_vm.desabilitar || !_vm.isValid},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"buscar"}}),_c('v-btn',{attrs:{"icon":"","small":"","color":"primary","loading":_vm.desabilitar,"disabled":_vm.desabilitar || !_vm.isValid},on:{"click":_vm.listar}},[_c('v-icon',[_vm._v("search")])],1),_c('v-spacer'),_c('v-switch',{staticClass:"hidden-sm-and-down",attrs:{"inset":"","hide-details":"","label":"Todo"},model:{value:(_vm.todoCompra),callback:function ($$v) {_vm.todoCompra=$$v},expression:"todoCompra"}})],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha)))])]}},{key:"item.fecha_comprobante",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha_comprobante)))])]}},{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(item.descripcion))])])]}},{key:"item.valor_unit_compra",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{class:item.moneda == 'S' ? 'primary--text' : 'success--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.valor_unit_compra)))])])]}},{key:"item.descto_item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{class:item.moneda == 'S' ? 'primary--text' : 'success--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(item.descto_item)))])])]}},{key:"item.valor_compra_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('strong',{class:item.moneda == 'S' ? 'primary--text' : 'success--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(item.stock_ingreso * item.valor_unit_compra - item.descto_item, 2))))])])]}},{key:"item.igv",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('strong',{class:item.moneda == 'S' ? 'primary--text' : 'success--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut((item.stock_ingreso * item.valor_unit_compra - item.descto_item) * item.igv, 2))))])])]}},{key:"item.precio_compra_total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('strong',{class:item.moneda == 'S' ? 'primary--text' : 'success--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut((item.stock_ingreso * item.valor_unit_compra - item.descto_item) + ((item.stock_ingreso
                * item.valor_unit_compra - item.descto_item) * item.igv), 2))))])])]}},{key:"item.otros_item",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',{class:item.moneda == 'S' ? 'primary--text' : 'success--text'},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(item.stock_ingreso * item.otros_item, 2))))])])]}},{key:"item.stock_actual",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(_vm._s(item.stock_actual))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }