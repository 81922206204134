<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="tipo_hoja == 'Ingresos' ? cabeceraHojaIngreso : cabeceraHojaEgreso"
          :items="tipo_hoja == 'Ingresos' ? hojaIngresos : hojaEgresos" :items-per-page="15" dense
          class="llime elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <v-toolbar-title class="hidden-sm-and-down dlime--text">ESTADÍSTICA MENSUAL</v-toolbar-title>
                <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                <!-- Formulario busqueda -->
                <v-btn icon small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>description</v-icon>
                </v-btn>
                <v-spacer />
                <v-select dense v-model="tipo_hoja" :items="tipo_hojas" label="Hoja" :rules="[v => !!v || 'Requerido']"
                  @change="hojaIngresos = [], hojaEgresos = []" class="mr-1" hide-details></v-select>
                <v-autocomplete dense v-model="idsucursal" :items="selectSucursales" :search-input.sync="buscar_sucursal"
                  label="Establecimiento" :rules="[v => !!v || 'Requerido']" @change="hojaIngresos = [], hojaEgresos = []"
                  class="mr-1" hide-details></v-autocomplete>
                <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                  min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true" range>
                  </v-date-picker>
                </v-menu>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <!-- Ingresos -->
          <template v-slot:item.venta="{ item }">
            <div class="text-right">
              <span>{{ item.venta | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.ccobrada="{ item }">
            <div class="text-right">
              <span>{{ item.ccobrada | formatearNumero }}</span>
            </div>
          </template>

          <template v-slot:item.banco="{ item }">
            <div class="text-right">
              <span>{{ item.banco | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.varios="{ item }">
            <div class="text-right">
              <span>{{ item.varios | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.transferencia="{ item }">
            <div class="text-right">
              <span>{{ item.transferencia | formatearNumero }}</span>
            </div>
          </template>

          <!-- Egresos -->
          <template v-slot:item.compra="{ item }">
            <div class="text-right">
              <span>{{ item.compra | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.cpagada="{ item }">
            <div class="text-right">
              <span>{{ item.cpagada | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.ncredito="{ item }">
            <div class="text-right">
              <span>{{ item.ncredito | formatearNumero }}</span>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      hojaIngresos: [],
      hojaEgresos: [],
      dialog: false,
      cabeceraHojaIngreso: [
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Detalle", value: "glosa", sortable: false },
        { text: "Ventas", value: "venta", sortable: false },
        { text: "CCobradas", value: "ccobrada", sortable: false },
        { text: "Bancos", value: "banco", sortable: false },
        { text: "Varios", value: "varios", sortable: false },
        { text: "Transferencia", value: "transferencia", sortable: false },
      ],
      cabeceraHojaEgreso: [
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Detalle", value: "glosa", sortable: false },
        { text: "Compras", value: "compra", sortable: false },
        { text: "CPagadas", value: "cpagada", sortable: false },
        { text: "Bancos", value: "banco", sortable: false },
        { text: "Varios", value: "varios", sortable: false },
        { text: "Transferencia", value: "transferencia", sortable: false },
        { text: "NCrédito", value: "ncredito", sortable: false },
      ],

      tipo_hoja: "",
      tipo_hojas: ["Ingresos", "Egresos"],

      idsucursal: "",
      selectSucursales: [],

      buscar_sucursal: "",
      buscar_movimiento: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      desabilitarbuscar: true,
    };
  },

  computed: {
    ...mapState(["usuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;
      if (me.tipo_hoja == "Ingresos") {
        axios.get("api/Movimientos/ReporteHojaIngreso", {
          params: {
            idsucursal: me.idsucursal,
            fechaInicio: me.dates[0],
            fechaFin:
              me.dates[1] == undefined
                ? me.dates[0] + " 23:59:59"
                : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.hojaIngresos = response.data;
            me.desabilitar = false;
            me.loading = false;
            me.snackBar({ cl: "success", msg: "Procesado correctamente." });
            //console.log(me.hojaIngresos);
          })
          .catch((err) => {
            me.desabilitar = false;
            me.loading = false;
            me.snackBar({ cl: "error", msg: "Ocurrio un error" });
            //console.log(err.response);
          });
      } else {
        axios.get("api/Movimientos/ReporteHojaEgreso", {
          params: {
            idsucursal: me.idsucursal,
            fechaInicio: me.dates[0],
            fechaFin: me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.hojaEgresos = response.data;
            me.desabilitar = false;
            me.loading = false;
            me.snackBar({ cl: "success", msg: "Procesado correctamente." });
            //console.log(me.hojaEgresos);
          })
          .catch((err) => {
            me.desabilitar = false;
            me.loading = false;
            me.snackBar({ cl: "error", msg: "Ocurrio un error" });
            //console.log(err.response);
          });
      }
    },

    select() {
      let su = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];
      if (this.tipo_hoja == "Ingresos") {
        this.hojaIngresos.map(function (x) {
          rows.push({
            CdSc: x.idsucursal,
            CdUs: x.idusuario,
            CdMv: x.idmovimiento,
            Fecha: moment(x.fecha).format("DD/MM/YYYY"),
            Detalle: x.glosa,
            Ventas: x.venta,
            CCobradas: x.ccobrada,
            Bancos: x.banco,
            Varios: x.varios,
            Transferencia: x.transferencia,
          });
        });

        var Detalle = XLSX.utils.json_to_sheet(rows);

        var wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, Detalle, "Hoja de Ingresos");

        XLSX.writeFile(
          wb,
          "HojadeIngresos " +
          moment(this.dates[0]).format("DD-MM-YYYY") +
          " al " +
          moment(this.dates[1]).format("DD-MM-YYYY") +
          ".xlsx"
        );
      } else {
        this.hojaEgresos.map(function (x) {
          rows.push({
            CdSc: x.idsucursal,
            CdUs: x.idusuario,
            CdMv: x.idmovimiento,
            Fecha: moment(x.fecha).format("DD/MM/YYYY"),
            Detalle: x.glosa,
            Compras: x.compra,
            CPagadas: x.cpagada,
            Bancos: x.banco,
            Varios: x.varios,
            Transferencia: x.transferencia,
            Ccredito: x.ncredito,
          });
        });

        var Detalle = XLSX.utils.json_to_sheet(rows);

        var wb = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(wb, Detalle, "Hoja de Egresos");

        XLSX.writeFile(
          wb,
          "HojadeEgresos " +
          moment(this.dates[0]).format("DD-MM-YYYY") +
          " al " +
          moment(this.dates[1]).format("DD-MM-YYYY") +
          ".xlsx"
        );
      }
    },
  },
};
</script>

