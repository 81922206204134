<template>
  <v-container fluid fill-height>
    <v-layout align-start>
      <v-flex>
        <!-- Listado Movimientos -->
        <v-data-table :headers="headers" :items="movimientos" :items-per-page="15" dense class="lcyan elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down dcyan--text">MOVIMIENTOS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Impresion movimientos -->
              <v-btn icon small color="print" @click="crearPDF()" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin Impresion movimientos -->
              <v-spacer />
              <!-- Busqueda de movimientos -->
              <v-autocomplete dense @keyup="selectUsuario()" :search-input.sync="buscar_usuario" v-model="idusuario"
                :disabled="!esAdministrador" :items="usuarios" label="Usuario"
                :rules="[v => !!v || 'Nombres es requerido']" clearable hide-details class="mr-1" @change="listar">
              </v-autocomplete>
              <v-select dense v-model="moneda" :items="tipo_servicios" label="Moneda" @change="listar" class="mr-1"
                hide-details></v-select>
              <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                offset-y min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="fecha_formateada" readonly v-on="on" hide-details></v-text-field>
                </template>
                <v-date-picker v-model="fecha" @input="menu = false" @change="fechaseleccionada(fecha), listar()">
                </v-date-picker>
              </v-menu>
              <!-- Fin Busqueda de movimientos -->
              <v-spacer />
            </v-toolbar>
          </template>
          <template v-slot:item.tipo_comprobante="{ item }">
            <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
          </template>
          <template v-slot:item.moneda="{ item }">
            <template v-if="item.moneda == 'S'">
              <span>S/</span>
            </template>
            <template v-else>
              <span>$</span>
            </template>
          </template>

          <template v-slot:item.ingreso="{ item }">
            <div class="text-right">
              <span>{{ item.ingreso | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.egreso="{ item }">
            <div class="text-right">
              <span>{{ item.egreso | formatearNumero }}</span>
            </div>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template slot="body.append">
            <tr v-if="totalsN.ingreso != 0 || totalsN.egreso != 0">
              <td :colspan="6" class="text-right font-weight-bold">TOTAL NO AFECTA CAJA:</td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totalsN.ingreso, 2) | formatearNumero }}
              </td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totalsN.egreso, 2) | formatearNumero }}
              </td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td :colspan="6" class="text-right font-weight-bold">TOTAL AFECTA CAJA:</td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totalsA.ingreso, 2) | formatearNumero }}
              </td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totalsA.egreso, 2) | formatearNumero }}
              </td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td :colspan="6" class="text-right font-weight-bold">SALDO INICIAL CAJA:</td>
              <td class="text-right font-weight-bold">{{ common.roundOut(moneda == 'S' ? saldoInicialMN : saldoFinalME,
                  2) |
                  formatearNumero
              }}</td>
            </tr>
          </template>
          <template slot="body.append">
            <tr>
              <td :colspan="6" class="text-right font-weight-bold">SALDO FINAL CAJA:</td>
              <td class="text-right font-weight-bold">{{ common.roundOut((moneda == 'S' ? saldoInicialMN : saldoFinalME)
                  +
                  totalsA.ingreso -
                  totalsA.egreso, 2) |
                  formatearNumero
              }}</td>
            </tr>
          </template>
        </v-data-table>
        <!--Fin Listado Movimientos -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      movimientos: [],
      dialog: false,
      headers: [
        { text: "CdMv", value: "idmovimiento", sortable: false },
        { text: "GLOSA", value: "glosa", sortable: false },
        { text: "MOVIMIENTO", value: "movimiento", sortable: false },
        { text: "COMPROBANTE", value: "tipo_comprobante", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "M", value: "moneda", sortable: false, divider: true },
        { text: "INGRESOS", align: "center", value: "ingreso", sortable: false, },
        { text: "EGRESOS", align: "center", value: "egreso", sortable: false, divider: true },
        { text: "PAGO", value: "descrip_pago", sortable: false },
      ],

      detalles: [],

      buscar_usuario: "",
      idusuario: "",
      usuarios: [],

      id: "",
      texto: "",

      saldoInicialMN: "",
      saldoInicialME: "",
      ingresoDiaMN: "",
      egresoDiaMN: "",
      ingresoDiaME: "",
      egresoDiaME: "",
      saldoFinalMN: "",
      saldoFinalME: "",


      moneda: "S",
      tipo_servicios: [
        { text: "Soles", value: "S" },
        { text: "Dolares", value: "D" },
      ],

      fecha_formateada: "",
      fecha: moment().format("YYYY-MM-DD"),
      modal: false,
      menu: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador"]),

    totalsA() {
      var resultado = [];

      for (var i = 0; i < this.movimientos.length; i++) {
        if (this.movimientos[i].idtipo_pago == 1 || this.movimientos[i].idtipo_pago == 2 || this.movimientos[i].idtipo_pago == 3) {
          resultado.push({
            ingreso: this.movimientos[i].ingreso,
            egreso: this.movimientos[i].egreso,
          });
        }
      }
      const totalsA = resultado.reduce((acc, d) => {
        acc.ingreso += d.ingreso
        acc.egreso += d.egreso
        return acc
      }, {
        ingreso: 0,
        egreso: 0,
      })
      return totalsA
    },

    totalsN() {
      var resultado = [];

      for (var i = 0; i < this.movimientos.length; i++) {
        if (this.movimientos[i].idtipo_pago == 4 || this.movimientos[i].idtipo_pago == 5 || this.movimientos[i].idtipo_pago == 6) {
          resultado.push({
            ingreso: this.movimientos[i].ingreso,
            egreso: this.movimientos[i].egreso,
          });
        }
      }
      const totalsN = resultado.reduce((acc, d) => {
        acc.ingreso += d.ingreso
        acc.egreso += d.egreso
        return acc
      }, {
        ingreso: 0,
        egreso: 0,
      })
      return totalsN
    },
  },

  watch: {},

  close() { },

  created() {
    this.idusuario = parseInt(this.usuario.idusuario);
    this.listar();
    this.fechaseleccionada(this.fecha);
    this.selectUsuario();
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.idusuario == null ? me.idusuario = parseInt(this.usuario.idusuario) : me.idusuario

      axios.get("api/Movimientos/ListarMovimientoFiltro/" +
        parseInt(me.usuario.idsucursal) +
        "/" +
        me.idusuario +
        "/" +
        me.moneda +
        "/" +
        me.fecha)
        .then(function (response) {
          me.movimientos = response.data;
          me.saldoAnterior();
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(error);
        });
    },

    saldoAnterior() {
      let me = this;
      var saldosAnteriorArray = [];
      // Limpiamos variables
      me.saldoInicialMN = 0;
      me.saldoInicialME = 0;
      me.ingresoDiaMN = 0;
      me.egresoDiaMN = 0;
      me.ingresoDiaME = 0;
      me.egresoDiaME = 0;

      axios.get("api/Saldos_Caja/MostrarSaldoDiaAnterior", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: me.idusuario,
          fecha: moment(me.fecha)
            .subtract(1, "day")
            .format("YYYY-MM-DD"),
        },
      })
        .then(function (response) {
          saldosAnteriorArray = response.data;
          me.saldoInicialMN = saldosAnteriorArray.saldo_mn;
          me.saldoInicialME = saldosAnteriorArray.saldo_me;
        })
        .catch(function (error) {
          //me.snackBar({ cl: "error", msg: error.response.data });
          //console.log(error.response.data);
        });
    },

    selectUsuario() {
      let me = this;

      var usuariosArray = [];

      axios.get("api/Usuarios/SelectUsuarioFiltro", {
        params: {
          buscar: me.buscar_usuario == "" ? "-" : me.buscar_usuario,
        },
      })
        .then(function (response) {
          usuariosArray = response.data;
          usuariosArray.map(function (x) {
            me.usuarios.push({
              text: x.nombre,
              value: x.idusuario,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_formateada = ddmmyy;
      //this.listar();
    },

    crearPDF() {
      var columns = [
        { header: "CdMv", dataKey: "idmovimiento" },
        { header: "GLOSA", dataKey: "glosa" },
        { header: "MOVIMIENTO", dataKey: "movimiento" },
        { header: "COMPROBANTE", dataKey: "tipo_comprobante" },
        { header: "PAGO", dataKey: "descrip_pago" },
        { header: "INGRESOS", dataKey: "ingreso" },
        { header: "EGRESOS", dataKey: "egreso" },
      ];

      var body = [];
      var foot = [];
      var totalingreso = 0.0;
      var totalegreso = 0.0;

      var body2 = [];
      var foot2 = [];
      var totalingreso2 = 0.0;
      var totalegreso2 = 0.0;

      for (var i = 0; i < this.movimientos.length; i++) {
        if (this.movimientos[i].idtipo_pago == 1 || this.movimientos[i].idtipo_pago == 2 || this.movimientos[i].idtipo_pago == 3) {
          totalingreso = totalingreso + parseFloat(this.movimientos[i].ingreso);
          totalegreso = totalegreso + parseFloat(this.movimientos[i].egreso);
          body.push({
            idmovimiento: this.movimientos[i].idmovimiento,
            glosa: this.movimientos[i].glosa,
            movimiento: this.movimientos[i].movimiento,
            tipo_comprobante: this.movimientos[i].serie_comprobante + "-" + this.movimientos[i].num_comprobante,
            descrip_pago: this.movimientos[i].descrip_pago,
            ingreso: numeral(this.movimientos[i].ingreso).format("###,##0.00"),
            egreso: numeral(this.movimientos[i].egreso).format("###,##0.00"),
          });
        }
        if (this.movimientos[i].idtipo_pago == 4 || this.movimientos[i].idtipo_pago == 5 || this.movimientos[i].idtipo_pago == 6) {
          totalingreso2 = totalingreso2 + parseFloat(this.movimientos[i].ingreso);
          totalegreso2 = totalegreso2 + parseFloat(this.movimientos[i].egreso);
          body2.push({
            idmovimiento: this.movimientos[i].idmovimiento,
            glosa: this.movimientos[i].glosa,
            movimiento: this.movimientos[i].movimiento,
            tipo_comprobante: this.movimientos[i].serie_comprobante + "-" + this.movimientos[i].num_comprobante,
            descrip_pago: this.movimientos[i].descrip_pago,
            ingreso: numeral(this.movimientos[i].ingreso).format("###,##0.00"),
            egreso: numeral(this.movimientos[i].egreso).format("###,##0.00"),
          });
        }

        if (i === this.movimientos.length - 1) {
          foot.push([
            { content: "TOTAL AFECTA CAJA:", colSpan: 5, },
            numeral(totalingreso).format("###,##0.00"),
            numeral(totalegreso).format("###,##0.00"),
          ], [
            { content: "SALDO INICIAL CAJA", colSpan: 5, },
            numeral(this.moneda == 'S' ? this.saldoInicialMN : this.saldoFinalME).format("###,##0.00"),
          ], [
            { content: "SALDO FINAL CAJA", colSpan: 5, },
            numeral((this.moneda == 'S' ? this.saldoInicialMN : this.saldoFinalME) + totalingreso - totalegreso).format("###,##0.00"),
          ])
          foot2.push([
            { content: "TOTAL NO AFECTA CAJA:", colSpan: 5 },
            numeral(totalingreso2).format("###,##0.00"),
            numeral(totalegreso2).format("###,##0.00"),
          ]);
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text(this.moneda == "" ? "MOVIMIENTOS" : "MOVIMIENTO DIARIO: " + this.fecha_formateada, doc.internal.pageSize.width / 2, 45, { align: "center", });
          doc.setFontSize(8);
          doc.text("ESTABLECIMIENTO: " + this.datos.sucursal, 40, 72);
          doc.text("USUARIO: " + this.buscar_usuario, 40, 86);
          doc.text(this.moneda == "" ? "" : this.moneda == "S" ? "MONEDA: SOLES" : "MONEDA: DOLARES", 260, 72);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Pagina " + String(i) + " de " + String(pageCount), doc.internal.pageSize.width / 2, 820,
            { align: "center", }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");


      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          idmovimiento: { cellWidth: 32, halign: "right" },
          glosa: { cellWidth: 120.28 },
          movimiento: { cellWidth: 101 },
          tipo_comprobante: { cellWidth: 78 },
          descrip_pago: { cellWidth: 60 },
          ingreso: { cellWidth: 62, halign: "right" },
          egreso: { cellWidth: 62, halign: "right" },
        },
      });

      // CPE Convertidos
      if (body2.length > 0) {
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 10,
          columns,
          body: body2,
          foot: foot2,
          styles: { overflow: "ellipsize", cellWidth: "wrap" },
          headStyles: {
            fillColor: [46, 78, 121],
            fontSize: 7,
            halign: "center",
          },
          bodyStyles: { textColor: 0, fontSize: 7 },
          footStyles: {
            fillColor: [255, 255, 255],
            textColor: 0,
            fontSize: 7,
            halign: "right",
          },
          columnStyles: {
            idmovimiento: { cellWidth: 32, halign: "right" },
            glosa: { cellWidth: 120.28 },
            movimiento: { cellWidth: 101 },
            tipo_comprobante: { cellWidth: 78 },
            descrip_pago: { cellWidth: 60 },
            ingreso: { cellWidth: 62, halign: "right" },
            egreso: { cellWidth: 62, halign: "right" },
          },
        });
      }
      // 515 ancho hoja
      addHeaders(doc);
      addFooters(doc);
      doc.save("Movimientos.pdf");
    },
  },
};
</script>
