import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#0064AC',
        secondary: '#EEEEEE',
        accent: '#82B1FF',
        error: '#EF5C54',
        info: '#59A9C2',
        success: '#499642',
        warning: '#FFC107',
        print: '#684644',
        damber: '#FF8F00',
        dgreen: '#2E7D32',
        ddorange: '#D84315',
        dteal: '#00695C',
        dpurple: '#6A1B9A',
        dpink: '#AD1457',
        ddpurple: '#4527A0',
        dindigo: '#283593',
        dcyan: '#00838F',
        dlime: '#9E9D24',
        dorange: '#EF6C00',

        lamber: '#FFF8E1',
        lgreen: '#E8F5E9',
        ldorange: '#FBE9E7',
        lteal: '#E0F2F1',
        lpurple: '#F3E5F5',
        lpink: '#FCE4EC',
        ldpurple: '#EDE7F6',
        lindigo: '#E8EAF6',
        lcyan: '#E0F7FA',
        llime: '#F9FBE7',
        lorange: '#FFF3E0',
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },

  //theme: { dark: false },
});
