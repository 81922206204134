<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="ventas" :items="ventaUsuarios" :items-per-page="15" dense
          class="lcyan elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <!-- Formulario busqueda -->
                <v-toolbar-title class="hidden-sm-and-down dcyan--text">VENTAS USUARIO</v-toolbar-title>
                <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                <v-btn icon small color="print" @click="crearPDF()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>print</v-icon>
                </v-btn>
                <v-spacer />
                <v-autocomplete dense @keyup="selectUsuario()" :search-input.sync="buscar_usuario" v-model="idusuario"
                  :items="usuarios" label="Usuario" :rules="[v => !!v || 'Nombres es requerido']" clearable hide-details
                  class="mr-1" @change="ventaUsuarios = []"></v-autocomplete>
                <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                  min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                    range>
                  </v-date-picker>
                </v-menu>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.moneda="{ item }">
            <span>{{ item.moneda == 'S' ? 'S/' : '$' }}</span>
          </template>
          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <span>{{ item.importe_total | formatearNumero }}</span>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import numeral from "numeral";

export default {
  data() {
    return {
      ventaUsuarios: [],
      dialog: false,
      ventas: [
        { text: "CdSc", value: "idsucursal", sortable: false },
        { text: "CdMv", value: "idmovimiento", sortable: false },
        { text: "Comprobante", value: "comprobante", sortable: false },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "", value: "moneda", sortable: false },
        { text: "Importe", value: "importe_total", sortable: false },
      ],

      buscar_usuario: "",
      idusuario: "",
      usuarios: [],

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
    };
  },

  watch: {
    buscar_usuario(newVal) {
      if (newVal === null) {
        this.buscar_usuario = "";
      }
    },
  },

  computed: {
    ...mapState(["usuario", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.selectUsuario();
  },

  methods: {
    ...mapActions(["snackBar"]),

    /* Listar venta usuario */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Movimientos/ReporteVentaUsuario", {
        params: {
          idusuario: parseInt(me.idusuario),
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.ventaUsuarios = response.data;
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
          //console.log(me.ventaUsuarios);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(err.response);
        });
    },

    // Seleccionar usuario por busqueda
    selectUsuario() {
      let me = this;

      var usuariosArray = [];

      axios.get("api/Usuarios/SelectUsuarioFiltro", {
        params: {
          buscar: me.buscar_usuario == "" ? "-" : me.buscar_usuario,
        },
      })
        .then(function (response) {
          usuariosArray = response.data;
          usuariosArray.map(function (x) {
            me.usuarios.push({
              //text: x.num_documento + " " + x.nombre,
              text: x.nombre,
              value: x.idusuario,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [
        { header: "CdSc", dataKey: "idsucursal" },
        { header: "CdMv", dataKey: "idmovimiento" },
        { header: "Comprobante", dataKey: "comprobante" },
        { header: "Fecha", dataKey: "fecha" },
        { header: "Cliente", dataKey: "cliente" },
        { header: "", dataKey: "moneda" },
        { header: "Importe", dataKey: "importe_total" },
      ];
      var body = [];
      var foot = [];

      var totalimporte = 0.0;

      for (var i = 0; i < this.ventaUsuarios.length; i++) {
        totalimporte =
          totalimporte + parseFloat(this.ventaUsuarios[i].importe_total);
        body.push({
          idsucursal: this.ventaUsuarios[i].idsucursal,
          idmovimiento: this.ventaUsuarios[i].idmovimiento,
          comprobante: this.ventaUsuarios[i].comprobante,
          fecha: moment(this.ventaUsuarios[i].fecha).format("DD/MM/YYYY"),
          cliente: this.ventaUsuarios[i].cliente,
          moneda: this.ventaUsuarios[i].moneda == "S" ? "S/" : "$",
          importe_total: numeral(this.ventaUsuarios[i].importe_total).format(
            "###,##0.00"
          ),
        });

        if (i === this.ventaUsuarios.length - 1) {
          foot.push([
            {
              content: "Total ",
              colSpan: 6,
            },
            numeral(totalimporte).format("###,##0.00"),
          ]);
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("Ventas Usuario", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
          doc.setFontSize(8);
          doc.text("NOMBRE: " + this.buscar_usuario, 40, 72);
          doc.text(
            "DESDE: " + moment(this.dates[0]).format("DD/MM/YYYY"),
            40,
            86
          );
          doc.text(
            "HASTA: " + moment(this.dates[1]).format("DD/MM/YYYY"),
            240,
            86
          );
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          idsucursal: { cellWidth: 30, halign: "right" },
          idmovimiento: { cellWidth: 30, halign: "right" },
          comprobante: { cellWidth: 60, halign: "center" },
          fecha: { cellWidth: 45, halign: "center" },
          cliente: { cellWidth: 265.28 },
          moneda: { cellWidth: 20, halign: "center" },
          importe_total: { cellWidth: 65, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("VentasUsuario.pdf");
    },
  },
};
</script>

