<template>
  <v-container fluid fill-height>
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="headers" :items="roles" :search="buscar" class="lindigo elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down dindigo--text">ROLES</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <v-text-field class="text-xs-center" v-model.trim="buscar" append-icon="search" dense label="Búsqueda"
                single-line hide-details></v-text-field>
              <v-spacer />
            </v-toolbar>
          </template>
          <template v-slot:item.condicion="{ item }">
            <template v-if="item.condicion">
              <span class="primary--text">Activo</span>
            </template>
            <template v-else>
              <span class="error--text">Inactivo</span>
            </template>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      roles: [],
      dialog: false,
      headers: [
        { text: "NOMBRE", value: "nombre", sortable: false },
        { text: "DESCRPCIÓN", value: "descripcion", sortable: false },
        { text: "ESTADO", value: "condicion", sortable: false },
      ],
      buscar: "",
    };
  },
  computed: {},

  watch: {},

  created() {
    this.listar();
  },
  methods: {
    listar() {
      let me = this;

      me.loading = true;
      axios.get("api/Roles/Listar")
        .then(function (response) {
          //console.log(response);
          me.roles = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
  },
};
</script>